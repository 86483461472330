<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Relatório de Clientes</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 120px;">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Período inicial"
                            v-model="form.start"
                            type="date"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Período final"
                            v-model="form.end"
                            type="date"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn @click="gerarXls" color="primary" :loading="loading">
                    <v-icon>mdi-file-table-outline</v-icon>&nbsp;Gerar XLS
                </v-btn>
            </v-card-actions>
        </v-card>
        <a ref="aDownload" style="display: none;"></a>
    </v-dialog>
</template>

<script>
export default {
    name: 'RelatorioClientes',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        loading: false,
        form: {
            start: '',
            end: '',
        },
    }),

    mounted() {
        this.form.start = this.moment().startOf('month').format('YYYY-MM-DD'),
        this.form.end = this.moment().endOf('month').format('YYYY-MM-DD');
    },

    methods: {
        gerarXls() {
            if (!this.form.start || !this.form.end) {
                this.notify('Informe o período', 'warning');
                return;
            }

            const data = {
                report: '\\App\\Reports\\ClientesReport',
                start: this.form.start,
                end: this.form.end,
            };
            const post = [];

            for (var i in data) {
                post.push(i + '=' + encodeURIComponent(data[i]));
            }
            const link = this.$refs.aDownload;
            this.loading = true;

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = ({target}) => {
                if (target.readyState === 4 && target.status === 200) {
                    const blob = xhr.response;
                    const url = window.URL || window.webkitURL;

                    link.download = `Relatorio_Clientes_${this.moment().format('DD_MM_YYYY')}.xls`;
                    link.href = url.createObjectURL(blob);
                    link.click();

                    this.loading = false
                }
            };
            xhr.open('POST', `${this.$urlAPI}/report/gerar-xls`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

            xhr.responseType = 'blob';

            xhr.send(post.join('&'));
        }
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
