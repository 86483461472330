<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Importar Clientes</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 120px;">
                <v-row>
                    <v-col>
                        <div style="border: solid 1px #999; border-radius: 5px;">
                            <b>Arquivo (csv)</b>
                            <FileInput
                                ref="file"
                                v-model="form.arquivo"
                                accept=".csv"
                                validateType="csv"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn @click="importar" color="primary" :loading="loading">
                    <v-icon>mdi-upload-box-outline</v-icon>&nbsp;Importar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileInput from '@/components/FileInput';

export default {
    name: 'ImportarClientes',

    components: {
        FileInput,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,
        form: {
            arquivo: null,
        },
    }),

    methods: {
        importar() {
            this.loading = true;
            this.$http.post('clientes/importar', this.form).then(resp => {
                this.loading = false;

                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify(`${resp.data.data} contatos importados`);
                this.reset();
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },
        
        reset() {
            this.form = {
                arquivo: null,
            };
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
