<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Clientes
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="primary"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                                Ações
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="relatorioClientesVisible = true">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-file-excel-outline
                                    </v-icon>
                                    <span>Exportar</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importarClientesVisible = true">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-upload-box-outline
                                    </v-icon>
                                    <span>Importar</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer/>
                    <v-text-field
                        @keypress.enter="consultar"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-btn @click="openCadastro" color="primary" fab small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <DataTable
                            @clickRow="editar"
                            v-model="search"
                            :headers="headers"
                            resource="clientes"
                            ref="datatable"
                            :height="230"
                            :dynamicSlots="dynamicSlots"
                        >
                            <template v-slot:whatsapp="{ item }">
                                <template>
                                    <div
                                        v-if="item.whatsapp"
                                        @click="abrirWhatsapp(item.whatsapp)"
                                        style="cursor: pointer;"
                                    >
                                        <v-icon color="green" small>mdi-whatsapp</v-icon>&nbsp;
                                        <u>{{ item.whatsapp }}</u>
                                    </div>
                                </template>
                            </template>
                            <template v-slot:ticket_medio="{ item }">
                                <template>
                                    <span style="color: green;">R$ {{ formatPrice(item.ticket_medio) }}</span>
                                </template>
                            </template>
                            <template v-slot:total_gasto="{ item }">
                                <template>
                                    <span style="color: green;">R$ {{ formatPrice(item.total_gasto) }}</span>
                                </template>
                            </template>
                            <template v-slot:action="{ item }">
                                <template>
                                    <v-btn @click.stop="excluir(item.id)" color="error" icon small>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                            </template>
                        </DataTable>
                    </v-card-text>
                </v-card>

                <Cliente
                    :visible="cadastroClienteVisible"
                    :id="clienteId"
                    @save="onCadastro"
                    @close="cadastroClienteVisible = false"
                />

                <RelatorioClientes
                    :visible="relatorioClientesVisible"
                    @close="relatorioClientesVisible = false"
                />

                <ImportarClientes
                    :visible="importarClientesVisible"
                    @close="importarClientesVisible = false"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer.vue';
import Cliente from '@/pages/cadastro/Cliente/Cliente.vue';
import DataTable from '@/components/DataTable.vue';
import RelatorioClientes from './RelatorioClientes.vue';
import ImportarClientes from './ImportarClientes.vue';

export default {
    name: 'Clientes',

    components: {
        BaseContainer,
        DataTable,
        Cliente,
        RelatorioClientes,
        ImportarClientes,
    },

    data: () => ({
        relatorioClientesVisible: false,
        importarClientesVisible: false,
        cadastroClienteVisible: false,
        clienteId: null,
        search: '',
        headers: [
            { text: 'Código', value: 'id' },
            { text: 'Nome', value: 'nome' },
            { text: 'WhatsApp', value: 'whatsapp' },
            { text: 'Qtd.Pedidos', value: 'qtd_pedidos', align: 'end' },
            { text: 'Ticket Médio', value: 'ticket_medio', align: 'end' },
            { text: 'Total Gasto', value: 'total_gasto', align: 'end' },
            { text: 'Ult.Compra', value: 'ultima_compra', align: 'center' },
            { text: 'Dias Sem Comprar', value: 'sem_comprar', align: 'end' },
            { text: 'Pontos', value: 'pontos', align: 'end' },
            { text: 'Excluir', value: 'action', align: 'end' },
        ],
        dynamicSlots: [
            { id: 'whatsapp', name: 'item.whatsapp' },
            { id: 'ticket_medio', name: 'item.ticket_medio' },
            { id: 'total_gasto', name: 'item.total_gasto' },
            { id: 'action', name: 'item.action' },
        ],
    }),

    methods: {
        consultar() {
            this.$refs.datatable.consultar();
        },

        abrirWhatsapp(whats) {
            const phone = '55' + whats.replace(/\D/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${phone}`);
        },

        close() {
            this.cadastroClienteVisible = false;
            this.clienteId = null;
        },

        openCadastro() {
            this.clienteId = null;
            this.cadastroClienteVisible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            this.clienteId = row.id;
            this.cadastroClienteVisible = true;
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.put(`clientes/${id}`, { status: 'excluido' }).then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify('Verifique sua conexão com a internet', 'warning');
                        return;
                    }

                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },
    },
}
</script>
